<template>
  <div class="cr-card-detail-option" v-show="show">
    <v-icon>
      mdi-email-outline
    </v-icon>

    <div class="cr-edit-wrapper" :class="{ 'py-2': isEdit }">
      <Email
        ref="email"
        v-for="(email, idx) in emailList"
        :key="idx"
        :email="email"
        :firstItem="idx === 0"
        :lastItem="idx + 1 === emailList.length"
        @update:value="v => $set(emailList, idx, v)"
        @click:add="addEmail"
        @click:delete="$delete(emailList, idx)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Email from "./Email.vue";

export default {
  components: { Email },
  data({ $store: { getters } }) {
    const { emailList } = getters["cardDetail/detail"];
    return { emailList: [...(emailList || [])] };
  },
  computed: {
    ...mapGetters("cardDetail", ["isEdit", "detail"]),
    show() {
      return true;
      /*
      if (this.isEdit) return true;
      return this.emailList.length > 0;
       */
    }
  },
  watch: {
    isEdit() {
      if (this.isEdit) return;

      const { emailList } = this.detail;
      this.$set(this, "emailList", [...(emailList || [])]);
    }
  },
  methods: {
    addEmail() {
      this.emailList = [...this.emailList, ""];
      this.$nextTick(() => {
        const idx = this.emailList.length - 1;
        this.$refs.email[idx]?.focus();
      });
    },
    getData() {
      if (!this.isEdit) return;

      for (let i = 0; i < this.emailList.length; i += 1) {
        this.$refs.email[i].valid();
      }
      return { emailList: this.emailList };
    }
  }
};
</script>
